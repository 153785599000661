<template>
  <app-layout>
    <div class="w-full flex flex-col rounded-lg items-center my-10 md:my-0">
      <h1 class="text-2xl md:text-4xl bold text-green-1003 md:mb-4 text-center">
        COMO FUNCIONA?
      </h1>
      <div class="flex flex-col md:flex-row w-max md:mx-auto">
        <div class="w-80 sm:w-96 md:w-64 md:mr-4">
          <img
            class="w-full"
            :src="require('../assets/images/how-it-works-syndicate.png')"
            alt="Box Sindicato Rural"
          />
          <div class="flex flex-col md:text-sm px-5 text-green-1002">
            <p class="bold">Contatos</p>
            <a href="tel:6539284809" class="block underline">(65) 3928-4809</a>
            <a
              href="mailto:colheita.talentos@senarmt.org.br"
              class="block underline"
              >colheita.talentos@senarmt.org.br</a
            >
          </div>
        </div>
        <div class="mb-5 w-80 sm:w-96 md:w-64 md:mr-4">
          <img
            class="w-full"
            :src="require('../assets/images/how-it-works-owner.png')"
            alt="Box Produtor e Empresas do Agro"
          />
          <div class="flex flex-col md:text-sm px-5 text-green-1002">
            <p class="bold">Contatos</p>
            <a href="tel:65999067411" class="block underline">(65)99906-7411</a>
            <p class="bold">ou</p>
            <a href="tel:65999189113" class="block underline">(65)99918-9113</a>
            <a
              href="mailto:colheita.talentos@senarmt.org.br"
              class="block underline"
              >colheita.talentos@senarmt.org.br</a
            >
          </div>
        </div>
        <div class="w-80 mb-8 sm:mb-10 sm:w-96 md:w-64">
          <img
            :src="require('../assets/images/how-it-works-talent.png')"
            alt="Box Talento"
          />
          <div class="flex flex-col md:text-sm px-5 text-green-1002">
            <a
              href="https://sistemafamato.org.br/portal/sindicatos/index.php"
              target="_blank"
              class="block bold underline"
              >Encontre o sindicato da sua região aqui!</a
            >
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';

export default {
  name: 'HowItWorks',

  title() {
    return `${process.env.VUE_APP_NAME} | Como Funciona`;
  },

  components: {
    AppLayout
  }
};
</script>
