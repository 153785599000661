<template>
  <app-layout>
    <div class="rounded-lg flex items-center my-5">
      <div
        class="w-11/12 md:w-6/12 mx-auto p-6 bg-white bg-opacity-80 shadow-xl"
      >
        <h1 class="font-bold text-lg text-green-1002 text-center bold">
          RECUPERAR SENHA
        </h1>
        <form @submit.prevent="recoveryPasswordAndRedirect(email)">
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Digite seu e-mail"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg focus:outline-none"
            v-model="email"
            autocomplete="off"
          />
          <button
            class="py-1 bg-green-1002 text-white text-center w-full mt-3 mb-3 text-lg focus:outline-none"
          >
            RECUPERAR
          </button>
          <router-link
            :to="{ name: 'login' }"
            class="block w-full text-center text-green-1002 mt-1"
          >
            voltar
          </router-link>
        </form>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import { mapActions } from 'vuex';

export default {
  name: 'Recovery',

  title() {
    return `${process.env.VUE_APP_NAME} | Recuperar Senha`;
  },

  components: {
    AppLayout
  },
  data() {
    return {
      email: null,
      disabled: null
    };
  },
  methods: {
    ...mapActions('auth', {
      recoveryPassword: 'recoveryPassword'
    }),
    recoveryPasswordAndRedirect(email) {
      this.disabled = 'disabled';

      this.recoveryPassword(email)
        .then(response => {
          this.disabled = null;

          this.$router.push({
            name: 'login'
          });

          this.$toast.success(response.data.message);
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.message);
        });
    }
  }
};
</script>
