<template>
  <app-layout>
    <div class="rounded-lg flex items-center justify-center my-5">
      <div class="w-11/12 md:w-6/12 p-6 bg-white bg-opacity-80 shadow-xl">
        <h1 class="font-bold text-lg text-green-1002 text-center bold">
          REDEFINIR SENHA
        </h1>
        <form @submit.prevent="resetPasswordAndRedirect">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="DIGITE SEU E-MAIL"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg focus:outline-none"
            v-model="email"
            autocomplete="off"
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="DIGITE SUA SENHA"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg focus:outline-none"
            v-model="password"
          />
          <input
            type="password"
            name="password_confirmation"
            id="password-confirmation"
            placeholder="CONFIRME SUA SENHA"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg focus:outline-none"
            v-model="password_confirmation"
          />
          <button
            type="submit"
            class="py-1 bg-green-1002 text-white text-center w-full mt-3 mb-3 text-lg focus:outline-none"
          >
            REDEFINIR
          </button>
          <router-link
            :to="{ name: 'home' }"
            class="block w-full text-center text-green-1002 mt-1"
          >
            voltar para a página principal
          </router-link>
        </form>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import { mapActions } from 'vuex';

export default {
  name: 'Reset',

  title() {
    return `${process.env.VUE_APP_NAME} | Redefinir Senha`;
  },

  components: {
    AppLayout
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: null,
      password: null,
      password_confirmation: null
    };
  },
  methods: {
    ...mapActions('auth', {
      resetPassword: 'resetPassword'
    }),
    resetPasswordAndRedirect() {
      this.disabled = 'disabled';

      this.resetPassword({
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then(() => {
          this.disabled = null;

          this.$router.push({
            name: 'login'
          });
        })
        .catch(({ response }) => {
          console.log(response);
          this.$toast.error(response.data.message);
        });
    }
  }
};
</script>
